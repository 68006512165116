// import router from "../router/index";
// import store from "../store";
import axios from 'axios';
import BaseApi from "./BaseApi";

export default class EmployeeService extends BaseApi {

   

    constructor(){
        super();
    }
 
    /**
     * Get all employees
     */
    async index()
    {
        return this.http.get(`${this.baseUrl}/employees`)
        .catch(this.handleErrorResponse);
    }

    /**
     * Get employees by business
     * @param {string} business bs slug
     */
    async indexByBusiness(business = null, office = '', status = 'ACTIVO')
    {
        return this.http.get(`${this.baseUrl}/employees/business/${business}?branch_office=${office}&status=${status}`)
        .catch(this.handleErrorResponse);
    }

    /**
     * Get employees by position
     * @param {string} position position id
     */
    async getByPosition(position = '')
    {
        return this.http.get(`${this.baseUrl}/catalogs/positions/${position}/employees`)
        .catch(this.handleErrorResponse);
    }

    /**
     * Get one employee
     * @param {*} id employee id
     */
    async get(id)
    {
        return this.http.get(`${this.baseUrl}/employees/${id}`)
        .catch(this.handleErrorResponse);
    }

    /**
     * Get leaving documents
     * @param {*} id employee id
     */
    async getLeavingDocuments(id)
    {
        return this.http.get(`${this.baseUrl}/employees/${id}/leaving-documents`)
        .catch(this.handleErrorResponse);
    }

    async getExternal(token)
    {
        return this.http.get(`${this.baseUrl}/employees/${token}/selfupdate`)
    }


    async getStatistics(employeeId)
    {
        return this.http.get(`${this.baseUrl}/employees/${employeeId}/statistic`)
        .catch(this.handleErrorResponse);
    }


    async getToken(employeeId)
    {
        return this.http.get(`${this.baseUrl}/employees/${employeeId}/token`)
        .catch(this.handleErrorResponse);
    }
   

    /**
     * STORE
     * Store employee
     * @param {string} step step to validate
     * @param {*} data data
     */
    async store(step = '', data)
    {
        return this.http.post(`${this.baseUrl}/employees${step}`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * UPDATE / Validate Update
     * 
     * @param {string} step validation step
     * @param {*} id employee id
     * @param {*} data Data
     */
    async update(step = '', id, data)
    {
        return this.http.put(`${this.baseUrl}/employees${step}/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * SAVE LEAVING DOCUMENTS
     * @param {*} id employee id
     * @param {*} data data
     */
    async updateLeavingDocuments(id,data)
    {
        return this.http.post(`${this.baseUrl}/employees/${id}/leaving-documents`, data)
        .catch(this.handleErrorResponse);
    }


    async changeStatus(id, reason)
    {
        return this.http.put(`${this.baseUrl}/employees/${id}/discharge`, {reason})
        .catch(this.handleErrorResponse);
    }



    async updateByEmployee(token, data)
    {
        let baseUrl = process.env.VUE_APP_BACKEND_URL;
        // axios;

        return axios.put(`${baseUrl}/employees/${token}/selfupdate`, data)
    }


    async exportEmployeeReport(employee_id)
    {
        return this.http.get(`${this.baseUrl}/employees/${employee_id}/report`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }


    async search(name)
    {
        return this.http.get(`${this.baseUrl}/employees/find-by?name=${name}`)
        .catch(this.handleErrorResponse);
    }

    exportEmployees(business, status)
    {
        return this.http.get(`${this.baseUrl}/employees/export/business/${business}/status/${status}`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }


    async getVacationByEmployee(id)
    {
        return this.http.get(`${this.baseUrl}/vacations/employee/${id}`)
        .catch(this.handleErrorResponse);
    }

    async getVacationEmployeeDetails(employee_id, numYear)
    {
        return this.http.get(`${this.baseUrl}/vacations/employee/${employee_id}/num_year/${numYear}`)
        .catch(this.handleErrorResponse);
    }

    async deleteVacation(id)
    {
        return this.http.delete(`${this.baseUrl}/vacations/${id}`)
        .catch(this.handleErrorResponse); 
    }


    async pdfVacations(id, year)
    {
        return this.http.get(`${this.baseUrl}/vacations/${id}/export/year/${year}`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    async getDaysOff(employee_id)
    {
        return this.http.get(`${this.baseUrl}/employees/${employee_id}/days-off`)
        .catch(this.handleErrorResponse);
    }


    async discharge(employee_id, data)
    {
        return this.http.put(`${this.baseUrl}/employees/${employee_id}/discharge`, data)
        .catch(this.handleErrorResponse);
    }

    async dischargeSaveAnswers(employee_id, data)
    {
        return this.http.put(`${this.baseUrl}/employees/${employee_id}/discharge/save-answers`, data)
        .catch(this.handleErrorResponse);
    }

}