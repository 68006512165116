<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scroll-y-transition"
        offset-y
        max-width="290px"
        min-width="290px"
        >
        <template v-slot:activator="{ on, attrs }">
          <textFieldForm
            :bindInput="attrs"
            :onInput="on"
            :label="label"
            :valueInput="time"
            :rule="rule"
            prependInnerIcon="mdi-clock-time-four-outline"
            :errorMessages="errorMessages"
            :clearable="true"
            :readonly="true"
            @valueChange="(v) => {time = v == null ? '' : v}"
          ></textFieldForm>
           
        </template>
        <v-time-picker
            v-if="menu"
            v-model="time"
            full-width
            color="primary"
            @click:minute="$refs.menu.save(time)"
            @change="setTime"
        ></v-time-picker>
    </v-menu>
</template>

<script>
  export default {
      props: ['valueInput', 'rule','label', 'hint', 'errorMessages'],
      computed:{
        time: {
          get: function() {
              
              return this.valueInput
          },
          set: function(value) {
            console.log(value, "estooo");
              this.$emit('valueChange', value)
          }
        }
      },
      data(){
        return {
          // time: '',
          menu: false,
          requiredRule: [
            v => !!v || 'Campo requerido'
          ]
        }
      },
      mounted() {
        if(typeof this.valueInput !== 'undefined')
          this.time = this.valueInput
      },
      methods: {
        setTime(timeIn){
            this.$emit('valueChange', timeIn)
        }
      }
  }
</script>

<style>

</style>