<template>
    <v-btn outlined :disabled="props.loading ? true : false" :color="props.loading ? '' : 'transparent'" :loading="props.loading" class="secondary-button-border" :style="{'border': `${props.color ? `var(--v-${props.color}-base)  1.5px solid`: ''}`}" elevation="0" :block="props.block" :to="{ name: props.to }" @click="props.click" >
        <div :class="`secondary-button button ${props.icon == '' ? 'no-icon' : 'with-icon'} ${props.classes} ${props.color ? `${props.color}--text text--darken-1` : 'grey--text text--darken-3'}`"  >
            <p class="mb-0 pl-1 ">{{props.text}}</p>
            <template v-if="props.icon != ''">
                &nbsp;&nbsp;<v-icon >{{props.icon}}</v-icon>
            </template>
        </div>
       
    </v-btn>
</template>
<script>
    export default {
        name: 'secondaryButton',
        props: ['props']
    }
</script>

<style lang="scss">

    .with-icon{
        justify-content: space-between;
    }

    .no-icon{
        justify-content: center;
    }
    .secondary-button{
        display: flex;
        // justify-content: space-between;
        align-items: center;
        width: 100%;
        // min-width: 110px;

        &-border{
            border: var(--v-secondary-base)  1.5px solid;
        }
    }

   

   
</style>