
import BaseApi from "../BaseApi";

export default class EmployeeEmployeeService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * my info
     * Get employee's information
     */
    async myInfo()
    {
        return this.http.get(`${this.baseUrl}/app/employees/me/detail`)
        .catch(this.handleErrorResponse);
    }



}