import Vue from 'vue';
import store from "../store";
import Permissions from '../views/managerViews/access/roles/permission';



export default class VueGatesPlugin{
    static init()
    {   
        
        // console.log(Vue.prototype.$gates.getRoles())
        // if(Vue.prototype.$gates.getRoles().length != 0)
        //     return;
        Vue.prototype.$gates.setRoles([]);
        Vue.prototype.$gates.setPermissions([]);
        let user = store.state.userData;
        Vue.prototype.$gates.setRoles([user.role.name]);
        // console.log(Vue.prototype.$gates.getRoles(), store.state.userData);
        let actions = ['all'];
        for (const property in user.role.permissions) {
            if(property == 'admin' && user.role.permissions[property] == true ){
                for (const property in Permissions) {
                    if(property != 'admin'){
                        actions.push(`${property}.show`);
                        actions.push(`${property}.create`);
                        actions.push(`${property}.update`);
                        actions.push(`${property}.delete`);
                        
                    }
                }
                break;
            }
            if(!(user.role.permissions[property] instanceof Array) && property != 'root'){
                for(const action in user.role.permissions[property]){
                    // console.log(action, user.role.permissions[property][action])
                    if(user.role.permissions[property][action]){
                        actions.push(`${property}.${action}`);
                    }
                }
            }
        }
        //!
        //!remove ?
        if(user.role?.permissions?.employee_site?.show){
            store.state.menu = false;
            store.state.disableResizeSidebar = true;
            actions = [];
            actions.push("employee_site.show")
        }
        // console.log(actions)
        //!
        //!

        if(user?.employee?.current_contract?.position?.area_manager){
            console.log("yes yes area rol")
            actions.push("area_rol_user.show")
        }


        Vue.prototype.$gates.setPermissions(actions);
        // console.log(Vue.prototype.$gates.getPermissions());
    }

    static clear()
    {
        
    }




    static checkRoutePermission(routeData)
    {
        console.log(Vue.prototype.$gates.hasAllPermissions(routeData.meta.permissions??'all'), Vue.prototype.$gates.unlessPermission(routeData.meta?.unlessPermission??""), "yyyyyyyyy");
        // if(Vue.prototype.$gates.getPermissions().length == 0)
        //     return true;
        // console.log(Vue.prototype.$gates.hasAllPermissions(routeData.meta.permissions??'all'), routeData)
        if( Vue.prototype.$gates.hasAllPermissions(routeData.meta.permissions??'all') && Vue.prototype.$gates.unlessPermission(routeData.meta?.unlessPermission??""))
            return true;
            
        return false;
    }
   
}

