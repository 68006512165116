import BaseApi from "./BaseApi";

export default class UserService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * Get assets
     */
    async index()
    {
        return this.http.get(`${this.baseUrl}/users`)
        .catch(this.handleErrorResponse);
    }

    /**
     * STORE
     * Store asset
     * @param {*} data 
     */
    async store(data)
    {
        return this.http.post(`${this.baseUrl}/users`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * GET
     * Get one asset
     * @param {string} id asset id
     */
    async get(id)
    {
        return this.http.get(`${this.baseUrl}/users/${id}`)
        .catch(this.handleErrorResponse);
    }

  

    /**
     * UPDATE
     * update asset
     * @param {string} id asset's id
     * @param {*} data  
     */
    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/users/${id}`, data)
        .catch(this.handleErrorResponse);
    }

   
    /**
     * DELETE
     * Delete asset
     * @param {string} id asset's id
     */
    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/users/${id}`)
        .catch(this.handleErrorResponse);
    }


    //changePassword
    async changePassword(data, slug)
    {
        return this.http.put(`${this.baseUrl}/users/${slug}/password`, data)
        .catch(this.handleErrorResponse);
    }



     /**
     * SELECT
     * Get user's business 
     */
     async getUserBusinessSelect()
     {
        return this.http.get(`${this.baseUrl}/flujo/business/availables`);
     }

}